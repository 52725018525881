/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo, Fragment } from "react"
import ReactJson from "react-json-view"
import moment from "moment"
import ReactDiffViewer from "react-diff-viewer"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import Paper from "@mui/material/Paper"
import { Tab, Tabs } from "@mui/material"

const JSONView = ({ title, data, compareTarget }) => {
    const orderJson = (jsonObject) =>
        Object.keys(jsonObject)
            .sort()
            .reduce((obj, key) => {
                obj[key] = jsonObject[key]
                return obj
            }, {})

    const view = useMemo(() => {
        if (!compareTarget) return <ReactJson src={data} />
        else {
            return (
                <>
                    <ReactDiffViewer
                        oldValue={JSON.stringify(orderJson(compareTarget.jsonObject), null, 2)}
                        newValue={JSON.stringify(orderJson(data), null, 2)}
                        leftTitle={compareTarget.title}
                        rightTitle={title}
                        splitView={true}
                        disableWordDiff
                    />
                </>
            )
        }
    }, [data, compareTarget])
    return view
}

const InspectionModal = ({ item, setSelectedItem, compareTarget, setCompareTarget }) => {
    const [jsonFiles, setJsonFiles] = useState([])
    const [currentTab, setCurrentTab] = useState(0)

    useEffect(() => {
        const getJsonFiles = async () => {
            const urls = item["jsonURLs"] || []
            const decodedUrls = urls.map(JSON.parse)
            const responses = await Promise.all(decodedUrls.map(({ S3URL }) => fetch(S3URL)))
            const jsonPackets = await Promise.all(responses.map((response) => response.json()))
            const mergedJsonData = decodedUrls.map((originalData, index) => ({
                ...originalData,
                downloadedJson: jsonPackets[index],
            }))
            setJsonFiles(mergedJsonData)
        }
        getJsonFiles()
    }, [item])

    return (
        <div className="modalOverlay" size="l">
            <Modal open onClose={() => setSelectedItem(null)}>
                <Box className="inspectionModal" component={Paper}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {item.level} {item.site}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {moment.unix(item.timestamp / 1000).fromNow()}
                    </Typography>

                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={currentTab} onChange={(event, newValue) => setCurrentTab(newValue)}>
                            <Tab label="Event" />
                            {jsonFiles.map(({ type }, index) => (
                                <Tab key={index} label={type} />
                            ))}
                            {item.stack_info && <Tab key={jsonFiles.length + 1} label={"Stack Trace"} />}
                        </Tabs>
                        {currentTab === 0 && (
                            <JSONView compareTarget={compareTarget} data={item} title={`${item.transactionid} event`} />
                        )}
                        {currentTab > 0 &&
                            jsonFiles
                                .filter((item, index) => index + 1 === currentTab)
                                .map(({ type, file, downloadedJson }, index) => {
                                    return (
                                        <JSONView
                                            key={index}
                                            compareTarget={compareTarget}
                                            data={downloadedJson}
                                            title={`${item.transactionid} ${file}: ${type}`}
                                        />
                                    )
                                })}

                        {currentTab === jsonFiles.length + 1 && (
                            <div className={`stackTrace stackTrace-${item.level}`}>
                                {item.stack_info.split("\n").map((line, index) => (
                                    <span key={index} style={{ marginLeft: `calc(${index} * 10px)` }}>
                                        {line}
                                    </span>
                                ))}
                            </div>
                        )}
                    </Box>
                    {!compareTarget &&
                        currentTab <= jsonFiles.length &&
                        (currentTab === 0 ? (
                            <Button
                                variant="primary"
                                onClick={() => {
                                    setCompareTarget({
                                        title: `${item.level} ${item.site} ${item.message.slice(0, 40)} ${
                                            item.message.length > 40 ? "..." : ""
                                        }`,
                                        id: item.transactionid,
                                        jsonObject: item,
                                    })
                                }}
                            >
                                Compare this with other documents
                            </Button>
                        ) : (
                            <Button
                                variant="primary"
                                onClick={() => {
                                    setCompareTarget({
                                        title: `${item.transactionid} ${jsonFiles[currentTab - 1].file}: ${
                                            jsonFiles[currentTab - 1].type
                                        }`,
                                        jsonObject: jsonFiles[currentTab - 1].downloadedJson,
                                    })
                                }}
                            >
                                Select For Compare
                            </Button>
                        ))}

                    <Button
                        variant="secondary"
                        onClick={() => {
                            setSelectedItem(null)
                        }}
                    >
                        Close
                    </Button>

                    {/* <TabPanel value={value} index={1}>
                        Item Two
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        Item Three
                    </TabPanel> */}
                </Box>
            </Modal>
        </div>
    )
}

export default InspectionModal
