/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import "./loader.css"

const Loader = ({ absolute }) => (
    <div className={absolute ? "loader loader-absolute" : "loader"}>
        <img className="loaderImg" src="/Icon.png" />
    </div>
)
export default Loader
