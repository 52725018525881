/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import "./style.css"
import { Typography, Grid } from "@mui/material"
import Paper from "@mui/material/Paper"
import { styled } from "@mui/material/styles"
import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';
import { getUserDocuments } from "../../appwriteUtils/actions"
import React, {  useEffect, useState, useCallback, useRef } from "react"
import Beacon from 'react-status-beacon'

import { uniqBy } from "lodash"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import ServiceConfigModal from "../../components/ServiceConfigModal";
import { collectionIds } from "../../appwriteUtils/config";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}))

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});
const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const EnabledView = (flags) => {
    // if beta is True, the icon is a tick, otherwise it is a cross
    return <Beacon size="1em" status={flags?.enabled ? 'positive' : 'dormant'} />

}

const IntegrationCard = ({integration, setSelectedIntegration}) => {

    const formatIntegrationName = (integrationName) => {
        if (integrationName) {
            try {
                let name = integrationName.replace(/-input|-output|-feature$/, "")
                name = name.replace(/-/g, " ")
                return name.replace(/\b\w/g, (word) => word.toUpperCase())

            }
            catch (e) {
                return "No Title"
            }

        }
        else {
            return "No Title"
        }
    }

    return (
        <Grid key={integration.$id} item>
            <ButtonBase onClick={() => setSelectedIntegration(integration)}  >
                <Grid   >
                    <Paper
                        sx={{
                            p: 2,
                            margin: 'auto',
                            maxWidth: 300,
                            height: 'auto',
                            minWidth: 300,
                            minHeight: 100,
                            flexGrow: 1,
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                        }}
                    >   {// center everything in the Grid component vertically and horizontally
                        }
                        <Grid container direction="row" justify="center" alignItems="center">
                        <Grid container direction="row" justify="left" alignItems="left">
                        <Grid item> <EnabledView  enabled={integration.enabled} gutterBottom /></Grid>
                        </Grid>

                            {/* <Grid container spacing={2}> */}
                            <Grid item>

                                <Img height="64" width="64" alt="complex" src={
                                    // get the length of integration.images
                                    // if integration.images exists, get the first image
                                    // if integration.images does not exist, get the default image
                                    integration.images ? integration.images[0] : "https://picsum.photos/64/64"


                                } />
                            </Grid>
                            <Grid item xs={12} sm container>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item xs>
                                        <Typography sx={{ fontSize: 12 }} variant="subtitle1" component="div">
                                            {formatIntegrationName(integration.$id)}
                                        </Typography>
                                        <Typography sx={{ fontSize: 8, fontWeight: "Medium" }} gutterBottom component="div">
                                            {   // if integration and integration.type are not undefined
                                                integration ? integration.type : "No Type"

                                            }

                                        </Typography>
                                        <Divider />
                                        <Typography sx={{ fontSize: 10 }} variant="body2" gutterBottom>
                                            {integration.description}
                                        </Typography>
                                        <Divider />
                                        <Typography sx={{ fontSize: 10 }} variant="body2" color="text.secondary">
                                            ID: {integration.$id}
                                        </Typography>

                                    </Grid>
                                    
                                </Grid>
                                
                                    
                                

                            </Grid>
                            
                        </Grid>
                        
                    </Paper>
                </Grid>
            </ButtonBase>
        </Grid>

    )
}

const deduplicate = items => {
    return uniqBy(items, item => {
        return item.logtransactionid || item.$id
    })
}

const Configuration = () => {
    const [integrationList, setIntegrationList] = useState([])
    const [open, setOpen] = useState(false);
    const [selectedIntegration, setSelectedIntegration] = useState(null)
    const [error, setError] = useState(null)
    const firstLoad = useRef(true)

    const [visibleEnabledStatus, setVisibleEnabledStatus] = useState(true)
    const [visibleBetaStatus, setVisibleBetaStatus] = useState(false)



    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const IntegrationCards = useCallback( () => {
        if (integrationList && integrationList.length > 0) {
            return integrationList
            .filter(integration => integration.enabled === visibleEnabledStatus && integration.beta === visibleBetaStatus)
            .map(integration => {
                return IntegrationCard({integration, setSelectedIntegration})
            })
        } else {
            // center a 
            return <Grid item><Alert severity="warning">No Integrations Loaded...</Alert></Grid>
        }
    }, [
        integrationList,
        setSelectedIntegration,
        visibleBetaStatus,
        visibleEnabledStatus,
        setVisibleBetaStatus,
        setVisibleEnabledStatus
    ])
    const fetchData = useCallback(
        ({ queries, paginationLastId }) => {
            getUserDocuments({ queries, paginationLastId, collectionId: collectionIds.flags })
                .then((response) => {
                    if (response) {
                        setIntegrationList((integrationList) =>

                            deduplicate([...integrationList, ...response.documents])

                        )
                    } else {
                        setError("No response from server")
                    }
                }
                )


                .catch((e) => {
                    console.error(e.message)
                })
        },
        []
    )

    // On mount run firt historic poll
    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false
            fetchData({})
        }
    }, [fetchData, firstLoad])






    return (
        <section>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {
                        error && error.message && typeof error.message === "string" ? error.message : error}


                </Alert>
            </Snackbar>



            <Grid container spacing={1}>
                <Grid item>
                    <Stack direction="row" spacing={1}>
                        <Item>Enabled <Switch checked={visibleEnabledStatus} onChange={e => setVisibleEnabledStatus(e.target.checked)}/></Item>
                        <Item>Beta <Switch checked={visibleBetaStatus} onChange={e => setVisibleBetaStatus(e.target.checked)}/></Item>
                    </Stack>

                </Grid>

            </Grid>
            <Divider />


            <Grid container spacing={2}>
                <IntegrationCards/>
            </Grid>

            { selectedIntegration && <ServiceConfigModal integration={selectedIntegration} onClose={() => setSelectedIntegration(false)} /> }

        </section>
    )
}

export default Configuration
