import { useEffect, useState } from "react"
import "./App.css"
import AppBar from "./AppBar"
import { BrowserRouter, Link } from "react-router-dom"
import { Route, Routes } from "react-router"

import Drawer from "@mui/material/Drawer"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { TextSnippet, FactCheck, Logout, Dangerous } from "@mui/icons-material"
import { Divider, Typography } from "@mui/material"
import Configuration from "./pages/Configuration"
import PCWebhookLog from "./pages/PCWebhookLog"
import Wasteland from "./pages/Wasteland"
import LogReader from "./pages/LogReader"
import LogIn from "./pages/LogIn"
import { logout, login } from "./appwriteUtils/actions"
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat"
import moment from "moment"
const routes = [
    {
        path: "/log-in",
        Component: LogIn,
        title: "Log In",
        hideRouteFromNav: true,
    },
    {
        path: "/",
        Component: LogReader,
        title: "Integration Logs",
        icon: <TextSnippet />,
    },
    {
        path: "/pcwebhooks",
        Component: PCWebhookLog,
        title: "Portconnect Logs",
        icon: <DirectionsBoatIcon />,
    },
    {
        path: "/configure",
        Component: Configuration,
        title: "Configure",
        icon: <FactCheck />,
    },

    {
        path: "/wasteland",
        Component: Wasteland,
        title: "Wasteland",
        icon: <Dangerous />,
    },
]

function App() {
    const [loggedInUser, setLoggedInUser] = useState(null)
    useEffect(() => {
        const loadStoredCredentials = async () => {
            const cookieFallback = JSON.parse(window.localStorage.getItem("cookieFallback") || "false")
            const userData = JSON.parse(window.localStorage.getItem("allotracDashUser") || "false")

            console.log("!!0", cookieFallback, userData)
            if (!cookieFallback || !userData) {
                console.log("!!1")
                // await logout()
            } else if (userData.expire < moment.unix()) {
                console.log("!!2")
                // await logout()
            } else {
                await login(userData.email, userData.password).then(setLoggedInUser(userData.providerUid))
            }
        }
        loadStoredCredentials()
    }, [setLoggedInUser])

    const [showDrawer, setShowDrawer] = useState(false)
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    {routes.map(({ path, Component, title }) => {
                        return (
                            <Route
                                key={path}
                                path={path}
                                element={
                                    <>
                                        <AppBar
                                            title={title}
                                            openMenuCallback={() => setShowDrawer(true)}
                                            // loggedInUser={loggedInUser}
                                        />
                                        <Component />
                                    </>
                                }
                            />
                        )
                    })}
                </Routes>

                <Drawer color="secondary" anchor="left" open={showDrawer} onClose={() => setShowDrawer(false)}>
                    <ListItem>
                        <Typography variant="h6" component="h4">
                            Devops Dash
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>{loggedInUser}</Typography>
                    </ListItem>
                    <Divider />
                    {routes
                        .filter(({ hideRouteFromNav }) => !hideRouteFromNav)
                        .map(({ title, path, icon }) => (
                            <Link key={path} to={path}>
                                <ListItem>
                                    <ListItemButton>
                                        <ListItemIcon>{icon}</ListItemIcon>
                                        <ListItemText primary={title} />
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        ))}

                    <ListItem>
                        <ListItemButton onClick={logout}>
                            <ListItemIcon>
                                <Logout />
                            </ListItemIcon>
                            <ListItemText primary={"Log Out"} />
                        </ListItemButton>
                    </ListItem>
                </Drawer>
            </BrowserRouter>
        </div>
    )
}

export default App
