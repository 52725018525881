import { Alert, Button, Paper, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useCallback, useState } from "react"
import { login } from "../../appwriteUtils/actions"
import Loader from "../../common/Loader"
import "./logIn.css"

const LogIn = () => {
    const [email, setEmail] = useState("")
    const [password, setPasword] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const logIn = useCallback(async () => {
        try {
            setLoading(true)
            await login({ email, password })
            setLoading(false)
            window.location = "/"
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }, [email, password])

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                margin: "20 auto",
            }}
        >
            <Typography variant="h2" component="h2" sx={{ flexGrow: 1 }}>
                Log In
            </Typography>

            <Box component={Paper} className="logInForm">
                <TextField
                    label={"Email"}
                    variant="filled"
                    value={email}
                    disabled={loading}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyPress={() => {
                        if (event.key === "Enter") {
                            logIn()
                        }
                    }}
                />
                <TextField
                    label={"Password"}
                    variant="filled"
                    type="password"
                    value={password}
                    disabled={loading}
                    onChange={(e) => setPasword(e.target.value)}
                    onKeyPress={() => {
                        if (event.key === "Enter") {
                            logIn()
                        }
                    }}
                />
                <Button onClick={logIn} disabled={loading}>
                    Log In
                </Button>
                {error && <Alert severity="error">{error}</Alert>}
                {loading && <Loader absolute />}
            </Box>
        </div>
    )
}

export default LogIn
