import { Appwrite } from "appwrite"

const appwrite = new Appwrite()

const endpoint = "https://services.allotr.ac" // We set the endpoint, change this if your using another endpoint URL.
const apiVersion = "v1"
const projectId = "pcompanion" // Here replace 'ProjectID' with the project ID that you created in your appwrite installation.

const collectionIds = {
    flags: "6298ab264647ceafff5d",
    exceptions: "exceptions",
    portconnect: "portconnect-events",
}

appwrite.setEndpoint(`${endpoint}/${apiVersion}`).setProject(projectId)

export { appwrite, collectionIds } // Finally export the appwrite object to be used in projects.
