const BOOL = "BOOL"
const STRING = "STRING"
const STRINGS = `${STRING}S`
const ENUM = "ENUM"

const FLAG_SCHEMA = {
    description: {
        type: STRING,
        label: "Description",
        priority: 0,
    },
    enabled: {
        type: BOOL,
        label: "Enabled",
        priority: 1,
    },
    beta: {
        type: BOOL,
        label: "Beta",
        priority: 2,
    },
    level: {
        type: STRING,
        label: "Log level",
        priority: 3,
    },
    type: {
        type: ENUM,
        label: "Type",
        priority: 4,
        required: true,
        options: [
            {
                value: "input",
                label: "Input",
            },
            {
                value: "output",
                label: "Output",
            },
            {
                value: "monitoring",
                label: "Monitoring",
            },
            {
                value: "feature",
                label: "Feature",
            },
        ],
    },
    tokens: {
        type: STRINGS,
        label: "Tokens",
        priority: 5,
    },
    phonenumbers: {
        type: STRINGS,
        label: "Phone numbers",
        priority: 6,
    },
    filterjobs: {
        type: STRINGS,
        label: "Filter jobs",
        priority: 7,
    },
    urls: {
        type: STRINGS,
        label: "URLs",
        priority: 8,
    },
    filtercustom: {
        type: STRINGS,
        label: "Filter custom",
        priority: 9,
    },
    usernames: {
        type: STRINGS,
        label: "User names",
        priority: 10,
    },
    passwords: {
        type: STRINGS,
        label: "Passwords",
        priority: 11,
    },
    responseformat: {
        type: STRING,
        label: "Response Format",
        priority: 12,
    },
    collections: {
        type: STRING,
        label: "Collections",
        priority: 13,
    },
    headers: {
        type: STRINGS,
        label: "headers",
        priority: 14,
    },
    images: {
        type: STRINGS,
        label: "Images",
        priority: 15,
    },
    channels: {
        type: STRINGS,
        label: "Channels",
        priority: 16,
    },
    emails: {
        type: STRINGS,
        label: "Emails",
        priority: 17,
    },
}

export default FLAG_SCHEMA
export { BOOL, STRING, STRINGS, ENUM }
