/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, {useCallback, useEffect} from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import gitInfo from "./generatedGitInfo.json"
import moment from "moment"
import { Button } from "@mui/material"
import { Login } from "@mui/icons-material"
import { Link } from "react-router-dom"



const CustomAppBar = ({ title, openMenuCallback, loggedInUser }) => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color="secondary">
                <Toolbar>
                <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={openMenuCallback}>
                        <MenuIcon />
                    </IconButton>
                    
                    <img className="appBarLogo" src="/Logo.png" />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    
                    
                            <Typography>
                                {`${gitInfo.gitBranch} - ${gitInfo.gitCommitHash} - ${gitInfo.gitCurrentUser} - ${moment
                                    .unix(gitInfo.timestamp)
                                    .fromNow()}`}
                            </Typography>
                        
                    
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default CustomAppBar
