import { appwrite } from "./config"

const getUserData = async () => {
    try {
        return appwrite.account.get()
    } catch (e) {
        console.error(e.message)
    }
}
const login = async ({ email, password }) => {
    try {
        const user = await appwrite.account.createSession(email, password)
        window.localStorage.setItem("allotracDashUser", JSON.stringify(user))

        return user
    } catch (e) {
        console.error(e.message)
    }
}
const logout = async () => {
    try {
        await appwrite.account.deleteSession("current")
    } catch (e) {
        console.error(e.message)
    } finally {
        window.localStorage.clear()
        if (window.location.pathname !== "/log-in") window.location = "/log-in"
    }
}
const creatUserDocument = async ({ collectionId, userData }) => {
    try {
        return appwrite.database.createDocument(collectionId, userData, ["role:all"], ["role:all"])
    } catch (e) {
        console.error(e.message)
    }
}
const getUserDocuments = async ({ queries, paginationLastId, collectionId }) => {
    try {
        console.log("Collection ID: ", collectionId)
        if (collectionId) {
            return appwrite.database.listDocuments(
                collectionId || undefined, // Collection ID duh
                queries || [], // Any search queries
                25, // result count limit
                0, // Pagination offset (0 because we'll use cursor for pagination)
                paginationLastId || undefined, // pagination cursor (last job id)
                "after", // Cursor direction
                ["timestamp"], // Sort attributes
                ["DESC"] // Sort types
            )
        }
    } catch (e) {
        console.error(e.message)
        return []
    }
}

const updateUserDocument = async ({ collectionId, documentId, data }) => {
    try {
        return appwrite.database.updateDocument(collectionId, documentId, { ...data }, ["role:all"], ["role:all"])
    } catch (e) {
        console.error(e.message)
    }
}
const deleteUserDocument = async ({ collectionId, documentId }) => {
    try {
        return appwrite.database.deleteDocument(collectionId, documentId)
    } catch (e) {
        console.error(e.message)
    }
}
export {
    getUserData,
    login,
    logout,
    creatUserDocument,
    getUserDocuments,
    updateUserDocument,
    deleteUserDocument,
    appwrite,
}
