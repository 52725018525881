/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react"
import moment from "moment"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"

// // eslint-disable-next-line react/prop-types
const LineItem = ({ onItemClick, itemKeys, item }) => {
    const [refresh, setRefresh] = useState(0)
    const refreshInterval = useRef()
    useEffect(() => {
        refreshInterval.current = setInterval(() => setRefresh(Math.random()), 10000)
        return () => {
            if (refreshInterval.current) clearInterval(refreshInterval.current)
        }
    })
    // prettier-ignore
    return (
        <TableRow className={`lineItem ${item.level}`} onClick={() => onItemClick(item)}>
            {itemKeys.map((key) => {
                if (key.key === "timestamp") {
                    return (
                        <TableCell key={key.key}>
                            <div className="verticalCell">
                                <div>{moment.unix(item[key.key] / 1000 + refresh).fromNow()}.</div>{" "}
                                <div className="tiny">{moment.unix(item[key.key] / 1000 + refresh).calendar()}</div>
                            </div>
                        </TableCell>
                    )

                } else if (key.key === "jsonURLs") {
                    return (
                        <TableCell key={key.key}>
                            {item[key.key].length}
                        </TableCell>
                    )
                } else if (key.key === "stack_info") {
                    return <TableCell key={key.key}>
                        {item[key.key] 
                            ? 
                                (item["level"] === "INFO" 
                                ? "🟢"
                                : item["level"] === "WARNING" 
                                ? "🟡" 
                                : item["level"] === "ERROR"
                                ? "🔴" 
                                : "⚪" )
                            : ""
                            }
                    </TableCell>}
                else {
                    return <TableCell key={key.key}>{item[key.key]}</TableCell>
                }
            })}
        </TableRow>
    )
}

const DataGrid = ({ items, itemKeys, onItemClick }) => {
    // prettier-ignore
    // sort items by timestamp descending
    const sortedItems = items.sort((a, b) => b.timestamp - a.timestamp)

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, maxWidth: "calc(100vw - 100px)" }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {itemKeys.map((header, index) => (
                            <TableCell key={index}>{header.label}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedItems.map((item, index) => (
                        <LineItem onItemClick={onItemClick} key={index} itemKeys={itemKeys} item={item} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default DataGrid
