/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback, useRef } from "react"
import { getUserDocuments, appwrite } from "../../appwriteUtils/actions"
import Loader from "../../common/Loader"
import { Query } from "appwrite"
import "./LogReader.css"
import { uniqBy } from "lodash"
import InspectionModal from "./InspectionModal"
import DataGrid from "./DataGrid"
import { Box, Button, FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { collectionIds } from "../../appwriteUtils/config"
const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const deduplicate = items => {
    return uniqBy(items, item => {
        return item.logtransactionid ||item.$id
    })
}

const LogReader = () => {
    const [search, setSearch] = useState("")
    const [notificationList, setNotificationList] = useState([])
    const [searchKey, setSearchKey] = useState("transactionid")
    const [paginationLastId, setPaginationLastId] = useState(null)
    const [allPagesLoaded, setAllPagesLoaded] = useState(false)
    const [error, setError] = useState(null)
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [compareTarget, setCompareTarget] = useState(null)
    const firstLoad = useRef(true)

    const fetchData = useCallback(
        ({ queries, paginationLastId }) => {
            getUserDocuments({ queries, paginationLastId, collectionId:collectionIds.exceptions})
                .then((response) => {
                    if (response) {
                    setNotificationList((notificationList) =>
                        deduplicate([...notificationList, ...response.documents])
                    )
                    if (response.documents.length === 0) {
                        setAllPagesLoaded(true)
                    } else {
                        setPaginationLastId(response.documents[response.documents.length - 1].$id)
                    }
                    setLoading(false)
                    }
                    else {
                        setError("Error fetching data")

                    }
                })
                .catch((e) => {
                    console.error(e.message)
                })
        },
        [setPaginationLastId]
    )

    const fetchNextPage = useCallback(() => {
        if (allPagesLoaded) return
        if (searchKey && search) {
            fetchData({ queries: [Query.search(`${searchKey}`, search)], paginationLastId })
        } else {
            fetchData({ paginationLastId })
        }
    }, [searchKey, search, paginationLastId, allPagesLoaded])

    const runNewSearch = useCallback(
        (event) => {
            // TODO: This needs some debounce
            setPaginationLastId(null)
            setAllPagesLoaded(false)
            setNotificationList([])
            fetchNextPage()
            
        },
        [searchKey, search]
    )

    useEffect(() => {
        appwrite.subscribe("documents", (response) => {
            // Callback will be executed on all account events.
            setNotificationList((notificationList) =>
            // if response.$collection is not portconnect-events, ignore it
                response.payload.$collection === collectionIds.exceptions ?
                    deduplicate([...notificationList, response.payload]) :
                    notificationList
            )
        })
    }, [setNotificationList])

    // On mount run firt historic poll
    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false
            fetchData({})
        }
    }, [fetchData, firstLoad])
    const itemKeys = [
        { key: "timestamp", label: "Timestamp" },
        { key: "site", label: "Service" },
        { key: "level", label: "Level" },
        { key: "message", label: "Message" },
        { key: "jobid", label: "Job ID" },
        { key: "custom1", label: "Container" },
        // { key: "transactionid", label: "Transaction ID" },
        // { key: "logtransactionid", label: "Log Transaction ID" },
        
        
        
        
        
        // { key: "custom2", label: "Custom 2" },
        // { key: "custom3", label: "Custom 3" },
        // { key: "jsonURLs", label: "JSON Files" },
        // { key: "stack_info", label: "Trace"}
    ]
    const indexKeys = [
        { key: "transactionid", label: "Transaction ID" },
        { key: "site", label: "Service" },
        
        { key: "level", label: "Level" },
        { key: "message", label: "Message" },
        
        { key: "jobid", label: "Job ID" },
        { key: "custom1", label: "Container" },
        // { key: "custom2", label: "Custom 2" },
        // { key: "custom3", label: "Custom 3" }
    ]
    
    const updateSearchKeyAndLog = (e) => {
        
        setSearchKey(e.target.value)
        
    }


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    return (
        <section>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {
                        error && error.message && typeof error.message === "string" ? error.message : error}


                </Alert>
            </Snackbar>
            <TextField 
                label="Search" 
                variant="filled" 
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={runNewSearch}
            />

            <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Search Index</FormLabel>
                <RadioGroup
                    row
                    name="searchIndexRadios"
                    value={searchKey}
                    onChange={e => setSearchKey(e.target.value)}
                >
                    {indexKeys.map((key) => (
                        <FormControlLabel
                        label={key.label}
                        value={key.key}
                        key={key.key} control={<Radio />} />
                    ))}
                </RadioGroup>
            </FormControl>
            <DataGrid
                items={notificationList.filter((item) =>
                    search && searchKey ? `${item[searchKey]}`.includes(search) : true
                )}
                // Stop judging me
                // prettier-ignore
                itemKeys={itemKeys}
                onItemClick={setSelectedItem}
            />
            {!allPagesLoaded && <Button onClick={fetchNextPage}>Next page </Button>}
            {loading && <Loader />}
            {selectedItem !== null && <InspectionModal 
                setSelectedItem={setSelectedItem} 
                item={selectedItem} 
                compareTarget={compareTarget}
                setCompareTarget={setCompareTarget} />
            }
            {
                compareTarget && <Box className="compare" component={Paper}>
                    <Typography variant="h6" component="h2">
                        Active Comparison: {compareTarget.id}
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        Now comparing all documents with {compareTarget.title}
                    </Typography>
                    <Button onClick={()=>setCompareTarget(null)}>Cancel</Button>
                </Box>
            }
        </section>
    )
}

export default LogReader
