/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Fragment, useCallback, useState } from "react"
import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Typography from "@mui/material/Typography"
import FLAG_SCHEMA, { BOOL, ENUM, STRING, STRINGS } from "./flagSchema"
import "./serviceConfigModal.css"
import { Divider, FormControlLabel, FormGroup, InputAdornment, MenuItem, Switch, TextField } from "@mui/material"
import { Delete } from "@mui/icons-material"
import debounce from "debounce"
import Loader from "../../common/Loader"
import { updateUserDocument } from "../../appwriteUtils/actions"
import { pick } from "lodash"
import { collectionIds } from "../../appwriteUtils/config"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(3),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(3),
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

export default function ServiceConfigModal({ integration, onClose }) {
    const [mutatedIntegration, setMutatedIntegration] = React.useState(integration)

    const formatIntegrationName = (integrationName) => {
        if (integrationName) {
            const integrationNameArray = integrationName.split("-")
            if (integrationNameArray.length > 1) {
                integrationNameArray.pop()
                // make the first letter of each word uppercase
                integrationNameArray.forEach((word, index) => {
                    // lol wtf copilot
                    integrationNameArray[index] = word.charAt(0).toUpperCase() + word.slice(1)
                })
                return integrationNameArray.join(" ")
            } else {
                return integrationName
            }
        } else {
            return "No Title"
        }
    }

    const updateMutatedIntegrationAttribute = useCallback(
        (attributeName, value) => {
            setMutatedIntegration((state) => {
                // This is dumb but preserves key order
                let freshMutatedIntegration = { ...state }
                freshMutatedIntegration[attributeName] = value
                return freshMutatedIntegration
            })
        },
        [setMutatedIntegration]
    )

    const updateMutatedIntegrationArrayedAttribute = useCallback(
        (attributeName, index, value) => {
            setMutatedIntegration((state) => {
                // This is dumb but preserves key order
                let freshMutatedIntegration = { ...state }
                freshMutatedIntegration[attributeName][index] = value
                console.log(freshMutatedIntegration[attributeName][index], value)
                return freshMutatedIntegration
            })
        },
        [setMutatedIntegration]
    )

    const appendMutatedIntegrationArrayedAttribute = useCallback(
        (attributeName, value) => {
            setMutatedIntegration((state) => {
                // This is dumb but preserves key order
                let freshMutatedIntegration = { ...state }
                freshMutatedIntegration[attributeName] = [...freshMutatedIntegration[attributeName], value]
                return freshMutatedIntegration
            })
        },
        [setMutatedIntegration]
    )

    const deleteMutatedIntegrationArrayedAttribute = useCallback(
        (attributeName, index) => {
            setMutatedIntegration((state) => {
                // This is dumb but preserves key order
                let freshMutatedIntegration = { ...state }
                let arrayToMutate = freshMutatedIntegration[attributeName]
                arrayToMutate.splice(index, 1)
                freshMutatedIntegration[attributeName] = arrayToMutate
                return freshMutatedIntegration
            })
        },
        [setMutatedIntegration]
    )

    const [saving, setSaving] = useState(false)
    const saveChangesToDatabase = useCallback(async () => {
        setSaving(true)
        const data = pick(mutatedIntegration, Object.keys(FLAG_SCHEMA))
        await updateUserDocument({ collectionId: collectionIds.flags, documentId: mutatedIntegration.$id, data })
        setSaving(false)
        onClose()
        window.location.reload()
    }, [mutatedIntegration, onClose, setSaving])

    return (
        <div>
            <BootstrapDialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose} className="configModalHeading">
                    {formatIntegrationName(integration.$id)}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <div className="serviceConfigModalFormWrapper">
                        {Object.keys(FLAG_SCHEMA)
                            .sort((a, b) => a.priority - b.priority)
                            .map((attributeName) => {
                                const schema = FLAG_SCHEMA[attributeName]
                                let attributeValue = mutatedIntegration[attributeName] || null
                                if (attributeValue === null) {
                                    switch (schema.type) {
                                        case STRING:
                                            attributeValue = ""
                                            break
                                        case STRINGS:
                                            attributeValue = []
                                            break
                                        case BOOL:
                                            attributeValue = false
                                            break
                                        default:
                                            break
                                    }
                                }

                                if (schema.type === STRING || schema.type === ENUM) {
                                    return (
                                        <Fragment key={attributeName}>
                                            <Typography variant="h6" component="h6">
                                                {schema.label}
                                            </Typography>
                                            <TextField
                                                id={attributeName}
                                                label={schema.label}
                                                variant="filled"
                                                select={schema.type === ENUM}
                                                value={attributeValue}
                                                disabled={saving}
                                                onChange={(e) =>
                                                    updateMutatedIntegrationAttribute(attributeName, e.target.value)
                                                }
                                            >
                                                {schema.type === ENUM &&
                                                    (schema.options || []).map(({ value, label }) => {
                                                        return (
                                                            <MenuItem key={value} value={value}>
                                                                {label}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </TextField>
                                            <Divider />
                                        </Fragment>
                                    )
                                }

                                if (schema.type === STRINGS) {
                                    return (
                                        <Fragment key={attributeName}>
                                            <Typography variant="h6" component="h6">
                                                {schema.label}
                                            </Typography>
                                            {(attributeValue || []).map((value, index) => (
                                                <div className="multiInput" key={`${attributeName}-${index}`}>
                                                    <TextField
                                                        id={attributeName}
                                                        label={schema.label}
                                                        variant="filled"
                                                        value={value}
                                                        disabled={saving}
                                                        onChange={(e) =>
                                                            updateMutatedIntegrationArrayedAttribute(
                                                                attributeName,
                                                                index,
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <Button
                                                        disabled={saving}
                                                        onClick={() =>
                                                            deleteMutatedIntegrationArrayedAttribute(
                                                                attributeName,
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <Delete />
                                                    </Button>
                                                </div>
                                            ))}
                                            <Button
                                                disabled={saving}
                                                onClick={() =>
                                                    appendMutatedIntegrationArrayedAttribute(attributeName, "")
                                                }
                                            >
                                                Add
                                            </Button>
                                            <Divider />
                                        </Fragment>
                                    )
                                }

                                if (schema.type === BOOL) {
                                    return (
                                        <Fragment key={attributeName}>
                                            <Typography variant="h6" component="h6">
                                                {schema.label}
                                            </Typography>
                                            <FormGroup className="modalSwitch">
                                                <FormControlLabel
                                                    disabled={saving}
                                                    control={
                                                        <Switch
                                                            checked={attributeValue}
                                                            onChange={(e) =>
                                                                updateMutatedIntegrationAttribute(
                                                                    attributeName,
                                                                    !attributeValue
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={`${schema.label} flag is ${
                                                        attributeValue ? "enabled" : "disabled"
                                                    }`}
                                                />
                                            </FormGroup>
                                            <Divider />
                                        </Fragment>
                                    )
                                }

                                return (
                                    <Fragment key={attributeName}>
                                        <Typography variant="h6" component="h6">
                                            Unsupported Type {schema.type} for attribute {attributeName}
                                        </Typography>

                                        <Divider />
                                    </Fragment>
                                )
                            })}
                    </div>
                    {saving && <Loader absolute />}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus disabled={saving} onClick={debounce(saveChangesToDatabase, 200)}>
                        Save changes
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}
